
.footer-oa-component {
    h5 {
        text-align: center;
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        margin-bottom: 22px;
        span {
            display: block;
            width: 100px;
            text-align: center;
            color: #2B221B;
            font-size: 18px;
            line-height: 18px;
            z-index: 2;
            background: #fff;
            font-weight: bold;
        }
    }
    h5::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 1px;
        background: #EAEFF3;
        z-index: -1;
    }
    div {
        margin-top: 30px;
        ul {
            display: flex;
            flex-wrap: wrap;
            
            li {
                margin: 0 32px 16px 0;
                a {
                    display: block;
                    font-size: 14px;
                    color: #777777;
                    line-height: 14px;
                }
            }
        }
    }
}
